import DateTimeService from "@/services/DateTimeService";

export const useUtils = () => {
  const localePath = useLocalePath();
  const { $store, $router } = useNuxtApp();
  const links = computed(() => $store.getters.getLinks);
  const i18n = useI18n();

  DateTimeService.dayjs.locale(i18n.locale.value);
  DateTimeService.dayjs.tz.setDefault("Europe/Berlin");

  const observeHeight = (observeRef: RefType, height: Ref) => {
    onMounted(() => {
      const resizeObserver = new ResizeObserver(() => {
        height.value = observeRef.value.$el.clientHeight;
      });

      const element = document.getElementById(observeRef.value.$el.id);
      if (element) {
        resizeObserver.observe(element);
      }
    });
  };

  const observeWidth = (observeRef: RefType, width: Ref) => {
    onMounted(() => {
      const resizeObserver = new ResizeObserver(() => {
        width.value = observeRef.value.$el.clientWidth;
      });

      const element = document.getElementById(observeRef.value.$el.id);
      if (element) {
        resizeObserver.observe(element);
      }
    });
  };

  const breakpoint = ref({
    clientWidth: 0,
    sm: false,
    md: false,
    mdAndUp: false,
    mdAndDown: false,
    lg: false,
    lgAndUp: false,
    xl: false,
  });
  const updateBreakpoints = () => {
    const clientWidth = document.body.clientWidth;

    breakpoint.value.clientWidth = clientWidth;
    breakpoint.value.sm = clientWidth < 400;
    breakpoint.value.md = clientWidth >= 400 && clientWidth < 800;
    breakpoint.value.mdAndDown = clientWidth < 800;
    breakpoint.value.mdAndUp = clientWidth >= 400;
    breakpoint.value.lg = clientWidth >= 800 && clientWidth < 1100;
    breakpoint.value.lgAndUp = clientWidth >= 800;
    breakpoint.value.xl = clientWidth > 1100;
  };
  onMounted(() => {
    try {
      window.addEventListener("resize", updateBreakpoints);
      updateBreakpoints();
    } catch (error) {
      console.error(error);
    }
  });

  const alphabeticSort = (array: string[]) => {
    return array.sort((a, b) => {
      const initialA = a.charAt(0).toUpperCase();
      const initialB = b.charAt(0).toUpperCase();

      if (initialA < initialB) {
        return -1;
      } else if (initialA > initialB) {
        return 1;
      } else {
        return 0;
      }
    });
  };

  const isLottie = (filename: String) => {
    return filename
      ? filename.endsWith(".json") || filename.endsWith(".lottie")
      : false;
  };

  const isStoryLink = (link: StorybookLink) => {
    return link && link.linktype !== "url";
  };

  const isEventLink = (link: StorybookLink) => {
    return (
      link &&
      link.linktype === "url" &&
      (link.url || link.cached_url).substring(0, 1) === "@"
    );
  };

  const iconSize = (iconSize: String) => {
    let value = null;
    switch (iconSize) {
      case "small":
        value = 40;
        break;
      case "large":
        value = 180;
        break;
      case "medium":
      default:
        value = 80;
    }

    return { width: value };
  };

  const lottieProps = (blok: Object, subComponent: Boolean = false) => {
    return !subComponent
      ? {
          loop: blok.lottie_loop,
          scale: blok.lottie_scale,
          speed: blok.lottie_speed,
        }
      : {
          lottie_loop: blok.lottie_loop,
          lottie_scale: blok.lottie_scale,
          lottie_speed: blok.lottie_speed,
        };
  };

  const localeStoryName = (story: Object) => {
    if (story?.translated_slugs) {
      const translation = story.translated_slugs.find(
        (translation) => translation.lang === story.lang,
      );
      if (translation?.name) {
        story.name = translation.name;
      }
    }

    return story;
  };

  const forceTrailingSlash = (url) => {
    if (url && url.substring(url.length - 1) !== "/") {
      url += "/";
    }

    return url;
  };

  const mapLinks = (
    link: Object,
    links: Array<Object>,
    locale: String | null = null,
  ) => {
    if (!locale) {
      locale = i18n.locale.value;
    }

    const transLink = links[link?.uuid || link?.id || link?.link?.id];

    if (transLink) {
      const alternateLink = transLink?.alternates?.find(
        (translation) => translation.lang === locale,
      );
      link.full_path = alternateLink?.translated_slug || transLink.slug;
      // if (link.full_path.endsWith("/")) {
      //   link.full_path = link.full_path.substring(0, link.full_path.length - 1);
      // }
      // link.full_path = link.full_path === 'home' ? '/' : link.full_path
      link.url = link.full_path;
      link.cached_url = link.url;
      link.name = alternateLink?.name || transLink.name;
    }

    return link;
  };

  const lastSlugPiece = (slug: String) => {
    const string = slug[slug.length - 1];
    if (string) {
      return string;
    } else {
      return lastSlugPiece(slug.slice(0, -1));
    }
  };

  const getUrlFromLink = (link: Object, addPrepandSlash: Boolean = false) => {
    if (!link) return;
    if (link.id) {
      link = mapLinks(link, links.value);
    }

    let url = link.url || link.cached_url;
    url =
      !link.id && link.cached_url
        ? link.cached_url
        : isStoryLink(link)
          ? localePath(`/${url}`)
          : url;

    url = url !== "/en/" ? url : null;

    // Remove first slash
    if (!addPrepandSlash && url?.charAt(0) === "/") {
      url = url.substring(1);
    }

    // if (url?.charAt(0) !== "#") {
    //   url = forceTrailingSlash(url);
    // }

    return url;
  };

  const findLinksWithId = (id: Number) => {
    const uuid = Object.keys(links.value).find(
      (uuid) => links.value[uuid].id === id,
    );
    return uuid ? links.value[uuid] : null;
  };

  const goto = (url) => {
    if (url.includes("http")) {
      window.open(url, "_blank");
    } else {
      $router.push(`/${url.replace(/^\//, "")}`);
    }
  };

  const navigateToStory = (story) => {
    const url = getUrlFromLink(story);
    if (url) {
      goto(url);
    }
  };

  function noGradient(string: String) {
    return string.replace(/##(.*?)##/g, "$1");
  }

  function replaceHeadlineGradient(
    querySelector: String = ".gradient-heading, h2, h3",
  ) {
    const elements = document.querySelectorAll(querySelector);

    if (elements) {
      elements.forEach((element) => {
        let content = element.innerHTML;

        if (element.classList.contains("no-gradient")) {
          return;
        }

        // Use regex to replace ##...##
        content = content.replace(
          /##(.*?)##/g,
          '<span class="hero-01-text-gradient">$1</span>',
        );

        element.innerHTML = content;
      });
    }
  }

  function parse(text: String) {
    // Das tatsächliche Zero-Width-Space-Zeichen (&ZeroWidthSpace;) einfügen
    return text.replace(/\[-\]/g, "\u200B");
  }

  function formateDate(date = null, format = "DD.MM.YYYY") {
    return date ? DateTimeService.dayjs(date).format(format) : null;
  }
  function formateTime(date = null) {
    return date ? DateTimeService.dayjs(date).format("HH:mm") : null;
  }
  function formatDateTime(date: any = null, formatDate = "DD.MM.YYYY") {
    date = date ?? DateTimeService.dayjs();
    return date
      ? `${formateDate(date, formatDate)} · ${formateTime(date)}`
      : null;
  }

  return {
    breakpoint,
    links,
    observeHeight,
    alphabeticSort,
    isLottie,
    isStoryLink,
    isEventLink,
    iconSize,
    localeStoryName,
    lottieProps,
    forceTrailingSlash,
    mapLinks,
    lastSlugPiece,
    getUrlFromLink,
    findLinksWithId,
    goto,
    navigateToStory,
    noGradient,
    replaceHeadlineGradient,
    parse,
    formateDate,
    formateTime,
    formatDateTime,
  };
};

type RefType = {
  value: {
    $el: {
      id: string;
      clientHeight: number;
      clientWidth: number;
    };
  };
};

type Ref = {
  value: number;
};

type StorybookLink = {
  linktype: string;
  url: string;
  cached_url: string;
};
